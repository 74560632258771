import { FC, useMemo } from 'react';
import { DataRouteObject, Navigate, useRoutes } from 'react-router-dom';
import { Route } from '@core/routes/Route';
import Layout from '@vms/Layout';
import Home from '@pages/Home';
import ConceptLocker from '@pages/ConceptLocker';
import ItemDetails from '@pages/ItemDetails';
import ChainPerformance from '@pages/ChainPerformance';
import FoodsFlavors from '@pages/FoodsFlavors';
import MyConcepts from '@pages/MyConcepts';
import ConceptPage from '@pages/ConcepPage';
import ChainsChart from '@pages/ChainsChart';
import Benchmark from '@pages/Benchmark';
import Search from '@pages/Search';
import FoodWatch from '@pages/FoodWatch';
import FoodWatchItem from '@pages/FoodWatchItem';
import ChainProfile from '@pages/ChainProfile';
import { useUserContext } from '@core/UserContext';
import SeasonalActivity from '@pages/SeasonalActivity';
import CategoryActivity from '@pages/CategoryActivity';

const Router: FC = () => {
    const { hasConceptLockerAccess } = useUserContext();
    const children = useMemo(() => {
        let result = [
                { path: '*', element: <Navigate to="/"/> },
                { path: Route.Home, element: <Home/> },
                { path: Route.HomeViewTable, element: <Home/> },
                { path: Route.HomeViewPeople, element: <Home/> },
                { path: Route.HomeViewChart, element: <Home/> },
                { path: Route.ItemDetails, element: <ItemDetails/> },
                { path: Route.ChainPerformance, element: <ChainPerformance/> },
                { path: Route.ChainProfile, element: <ChainProfile/> },
                { path: Route.FoodsFlavors, element: <FoodsFlavors/> },
                { path: Route.Benchmark, element: <Benchmark/> },
                { path: Route.FoodWatch, element: <FoodWatch/> },
                { path: Route.FoodWatchItemCreate, element: <FoodWatchItem/> },
                { path: Route.FoodWatchItemEdit, element: <FoodWatchItem/> },
                { path: Route.Search, element: <Search/> },
                { path: Route.CLItemDetails, element: <ItemDetails/> },
                { path: Route.SeasonalActivity, element: <SeasonalActivity/> },
                { path: Route.CategoryActivity, element: <CategoryActivity/> },
            ];

        if (hasConceptLockerAccess) {
            result = [
                ...result,
                { path: Route.ChainsChart, element: <ChainsChart/> },
                { path: Route.ConceptLocker, element: <ConceptLocker/> },
                { path: Route.ConceptLockerViewTiles, element: <ConceptLocker/> },
                { path: Route.ConceptLockerViewPeople, element: <ConceptLocker/> },
                { path: Route.ConceptLockerViewChart, element: <ConceptLocker/> },
                { path: Route.MyConcepts, element: <MyConcepts/> },
                { path: Route.ConceptPageCreate, element: <ConceptPage/> },
                { path: Route.ConceptPageEdit, element: <ConceptPage/> },
            ];
        }

        return result;
    }, [hasConceptLockerAccess]);

    const routing = useRoutes([{
        path: '/',
        element: <Layout/>,
        children,
    }]);
    return <>{routing}</>
};

export default Router;