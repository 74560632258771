import { ID } from '@models';
import useFetch from '@hooks/useFetch';
import { useEffect, useMemo, useState } from 'react';
import { components } from '@api/api';
import { iItem, Item } from '@models/Item';
import { useChainProfileFilters } from '@core/selectors/useChainProfileFilters';

export function useChainProfileRecentlyReleasedItems(chainId: ID) {
    const [data, setData] = useState<iItem[]>([]);

    const filter = useChainProfileFilters();

    const payload = useMemo((): components['schemas']['ChainProfileRecentlyReleasedItemsRequest'] => {
        return {
            id: chainId as number,
            filter,
            page: {
                currentPage: 1,
                perPage: 100,
            }
        };
    }, [filter, chainId]);

    const result = useFetch<components['schemas']['ChainProfileRecentlyReleasedItemsResponse']>({
        url: '/api/ChainProfile/RecentlyReleasedItems',
        method: 'post',
        payload,
    });

    useEffect(() => {
        if (!result.isLoading) {
            setData((result.data?.items || []).map(i => new Item({ apiModel: i })))
        }
    }, [result.isLoading, result.data]);

    return {
        isLoading: result.isLoading,
        data,
    }
}