import { FC, memo, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import FiltersPopup from '@vms/FiltersPopup';
import HeaderBar from '@components/HeaderBar';
import { getSeasonEndMonth, getSeasonStartMonth, iOption, Season, Timeframe } from '@models';
import { Route } from '@core/routes/Route';
import TilesView from '@vms/TilesView';
import Wrapper from '@components/Wrapper';
import { iSelectOption, SelectedOptionsProvider } from '@hooks/useSelectedOptions';
import HomeTableView from '@vms/ItemsTable/HomeTableView';
import HomePeopleView from '@vms/ItemsTable/HomePeopleView';
import SelectAmong from '@vms/SelectAmong';
import SelectNorm from '@vms/SelectNorm';
import SelectPageView from '@vms/SelectPageView';
import SelectMetric from '@vms/SelectMetric';
import SelectDisplay from '@vms/SelectDisplay';
import { FiltersOverridesContext, useFiltersContext } from '@core/FiltersContext';
import ChartView from '@vms/ChartView';
import SearchPageActionBar from '@vms/SearchPageActionBar/SearchPageActionBar';
import { components } from '@api/api';
import { useHomeChartData } from '@api/useHomeChartData';
import TimeframesBarChart from '@components/TimeframesBarChart';
import Loader from '@components/Loader';
import Button from '@components/Button';
import ButtonTabs from '@components/ButtonTabs';
import MostPopularTable from '@pages/Home/MostPopularTable';
import FastestGrowingTable from '@pages/Home/FastestGrowingTable';
import AnimatedNumber from '@components/AnimatedNumber';
import { iFilterValue } from '@models/Filter';
import { equalInLC } from '@core/utils/string';
import cn from 'classnames';
import { RxCross2 } from 'react-icons/rx';
import { InView } from 'react-intersection-observer';
import ButtonClipboardCopy from '@components/ButtonClipboardCopy';
import { usePptHistoricTrendReport } from '@api/usePptHistoricTrendReport';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import Popup from '@components/Popup';
import { Tooltip } from 'react-tooltip';
import qs from 'query-string';
import TimeframesBarChartData from '@models/TimeframesBarChartData';

const VIEWS: iOption[] = [
    { id: Route.Home, title: 'Tiles' },
    { id: Route.HomeViewTable, title: 'Table' },
    { id: Route.HomeViewPeople, title: 'People' },
    { id: Route.HomeViewChart, title: 'Chart' },
];

const AMONG_PAGES: Route[] = [
    Route.Home,
    Route.HomeViewTable,
    Route.HomeViewChart,
];

const METRIC_PAGES: Route[] = [
    Route.HomeViewPeople,
];

const NORM_PAGES: Route[] = [
    Route.Home,
    Route.HomeViewTable,
    Route.HomeViewPeople,
];

const HomeComponent: FC = () => {
    const { value: {
        segments,
        isGroceryDeli,
        isMealKit,
    } } = useFiltersContext();
    const navigate = useNavigate();
    const { pathname, search } = useLocation();
    const chartData = useHomeChartData();

    const { barId, timeframe } = qs.parse(search);

    const [totalRecords, setTotalRecords] = useState<number | null>(null);
    const [selectedBarId, setSelectedBarId] = useState<string | undefined>(barId as string || undefined);
    const [scrollToBarId, setScrollToBarId] = useState<string | null>(barId as string);
    const [chartInterval, setChartInterval] = useState<Timeframe>(timeframe as Timeframe || Timeframe.Month);
    const [isChartOpened, setIsChartOpened] = useState(true);
    const [isBottomHeaderStick, setIsBottomHeaderStick] = useState(false);
    const [selectedItems, setSelectedItems] = useState<iSelectOption[]>([]);
    const [appliedFilterPayload, setAppliedFilterPayload] = useState<components['schemas']['SearchFilterModel']>({});
    const historicTrendReport = usePptHistoricTrendReport();

    useEffect(() => {
        if (chartData.isInitialLoadPerformed) {
            setSelectedBarId(undefined);
        }
    }, [chartInterval]);


    useEffect(() => {
        if (chartData.isLoading && chartData.isInitialLoadPerformed) {
            setSelectedBarId(undefined);
        }
    }, [chartData.isLoading]);

    useEffect(() => {
        if (barId) {
            const barData = TimeframesBarChartData.getBarData(barId as string, chartInterval);
            chartData.fetchIntervalDetails(barData.year, barData.season, barData.month);
        }
    }, [barId]);

    useEffect(() => {
        if (search) {
            navigate(Route.Home, { replace: true });
        }
    }, [search, navigate]);

    useEffect(() => {
        if (selectedBarId !== scrollToBarId) {
            setScrollToBarId(null);
        }
    }, [selectedBarId])

    const filterValueOverride = useMemo((): Partial<iFilterValue> | undefined => {
        if (!selectedBarId) return undefined;

        let from, to;
        switch (chartInterval) {
            case Timeframe.Month: {
                from = selectedBarId;
                to = selectedBarId;
                break;
            }
            case Timeframe.Season: {
                const [season, year] = selectedBarId.split(' ');
                let fromMonth = getSeasonStartMonth(season as Season),
                    fromYear = Number(year),
                    toMonth = getSeasonEndMonth(season as Season),
                    toYear = Number(year);

                if (equalInLC(fromMonth, 'Dec')) fromYear--;

                from = `${fromMonth} ${fromYear}`;
                to = `${toMonth} ${toYear}`;
                break;
            }
            case Timeframe.Year: {
                from = `Jan ${selectedBarId}`;
                to = `Dec ${selectedBarId}`;
                break;
            }
        }

        return {
            date: {
                period: { from, to, },
                season: null,
                withinMonth: null,
            }
        };
    }, [chartInterval, selectedBarId]);

    const isBottomCounterShown = useMemo(() => {
        if (isBottomHeaderStick) return true;
        if (pathname === Route.HomeViewChart) return true;
        if (!isChartOpened) return true;
        return selectedBarId && !chartData.isDetailsLoading;
    }, [pathname, chartData, isChartOpened, selectedBarId, isBottomHeaderStick]);

    const hideChartTables = useMemo((): boolean => {
        return segments.length === 0 && (isMealKit || isGroceryDeli);
    }, [isMealKit, isGroceryDeli, segments]);

    return (
        <SelectedOptionsProvider value={selectedItems} setValue={setSelectedItems}>
            <div className="Home section" style={{ position: 'relative', minHeight: 'calc(100vh - 180px)' }}>
                <Wrapper>
                    {pathname !== Route.HomeViewChart && (
                        <>
                            <div className={cn("Home__bar-chart", !isChartOpened && 'is-collapsed')}>
                                <HeaderBar className="Home__bar-header">
                                    <div className="Home__header-section">
                                        {!!chartData.totalRecords && (
                                            <div
                                                style={{
                                                    fontSize: 30,
                                                    paddingRight: 16,
                                                }}
                                                className="font-caption color-dimyellow"
                                            >
                                                <AnimatedNumber value={chartData.totalRecords}/>
                                            </div>
                                        )}
                                        <div>Launched menu items & LTO’s</div>
                                    </div>
                                    <div className="Home__header-section">
                                        <ButtonTabs
                                            data={[
                                                {
                                                    id: 'month', title: 'Month',
                                                    buttonProps: {
                                                        onClick: () => setChartInterval(Timeframe.Month),
                                                    }
                                                },
                                                {
                                                    id: 'season', title: 'Season',
                                                    buttonProps: {
                                                        onClick: () => setChartInterval(Timeframe.Season),
                                                    }
                                                },
                                                {
                                                    id: 'year', title: 'Year',
                                                    buttonProps: {
                                                        onClick: () => setChartInterval(Timeframe.Year),
                                                    }
                                                },
                                            ]}
                                            activeItemId={chartInterval}
                                        />
                                    </div>
                                </HeaderBar>
                                <div className="Home__bar-chart-section">
                                    <ButtonClipboardCopy
                                        style={{ top: 40 }}
                                        caption="PPT"
                                        isBusy={historicTrendReport.isLoading}
                                        onClick={() => historicTrendReport.load(chartInterval)}
                                        notify={false}
                                    >
                                        <div
                                            className="ActionButton__icon font-icons"
                                            style={{
                                                fontSize: 20,
                                                marginBottom: 3,
                                            }}
                                        >
                                            &#59702;
                                        </div>
                                    </ButtonClipboardCopy>
                                    {(chartData.isLoading || chartData.data === null) && (
                                        <Loader/>
                                    )}
                                    {!chartData.isLoading && chartData.data !== null && (
                                        <>
                                            <TimeframesBarChart
                                                key={chartInterval}
                                                data={chartData.data}
                                                currentView={chartInterval}
                                                selectedBarId={selectedBarId}
                                                scrollToBarId={scrollToBarId}
                                                selectCurrentSeasonFirst
                                                onBarClick={(id) => {
                                                    const newId = id === selectedBarId ? undefined : id;
                                                    setSelectedBarId(newId);
                                                    if (newId !== undefined) {
                                                        const barData = TimeframesBarChartData.getBarData(newId, chartInterval);
                                                        chartData.fetchIntervalDetails(barData.year, barData.season, barData.month);
                                                    }
                                                }}
                                                legendMessage={`click on a bar to see that ${chartInterval}'s launches and featured foods & flavors`}
                                                showYAxis={chartInterval === Timeframe.Month}
                                            />
                                            {!hideChartTables && (
                                                <>
                                                    {!selectedBarId && (
                                                        <div className="Home__bar-chart-widget HomeWidgetTable">
                                                            <div
                                                                className="HomeWidgetTable__header"
                                                                data-tooltip-id="most-popular-table-all"
                                                            >
                                                                Most Popular
                                                            </div>
                                                            <Popup hasWrapper={false}>
                                                                <Tooltip
                                                                    style={{ width: 250, zIndex: 4, textAlign: 'center' }}
                                                                    id="most-popular-table-all"
                                                                    positionStrategy="fixed"
                                                                    place="top"
                                                                >
                                                                    number of chain menu item launches featuring this food or flavor
                                                                </Tooltip>
                                                            </Popup>
                                                            <div className="HomeWidgetTable__content">
                                                                <MostPopularTable
                                                                    data={chartData.totalMostPopularData}
                                                                    isLoading={chartData.isLoading}
                                                                />
                                                            </div>
                                                        </div>
                                                    )}
                                                    {selectedBarId && (
                                                        <>
                                                            <div className="Home__bar-chart-widget HomeWidgetTable">
                                                                <div
                                                                    className="HomeWidgetTable__header"
                                                                    data-tooltip-id="most-popular-table"
                                                                >
                                                                    Most Popular
                                                                </div>
                                                                <Popup hasWrapper={false}>
                                                                    <Tooltip
                                                                        style={{ width: 250, zIndex: 4, textAlign: 'center' }}
                                                                        id="most-popular-table"
                                                                        positionStrategy="fixed"
                                                                        place="top"
                                                                    >
                                                                        number of chain menu item launches featuring this food or flavor
                                                                    </Tooltip>
                                                                </Popup>
                                                                <div className="HomeWidgetTable__content">
                                                                    <MostPopularTable
                                                                        data={chartData.mostPopularData}
                                                                        isLoading={chartData.isDetailsLoading}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="Home__bar-chart-widget HomeWidgetTable">
                                                                <div
                                                                    className="HomeWidgetTable__header"
                                                                    data-tooltip-id="fastest-growing-table"
                                                                >
                                                                    Fastest Growing
                                                                </div>
                                                                <Popup hasWrapper={false}>
                                                                    <Tooltip
                                                                        style={{ width: 250, zIndex: 4, textAlign: 'center' }}
                                                                        id="fastest-growing-table"
                                                                        positionStrategy="fixed"
                                                                        place="top"
                                                                    >
                                                                        1-year growth rate (# of launches in the selected {chartInterval} vs. the same period 1 year prior)
                                                                    </Tooltip>
                                                                </Popup>
                                                                <div className="HomeWidgetTable__content">
                                                                    <FastestGrowingTable
                                                                        data={chartData.fastestGrowingData}
                                                                        isLoading={chartData.isDetailsLoading}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                </>
                                            )}
                                        </>
                                    )}
                                </div>
                            </div>
                            <div className="Home__bar-chart-toggle">
                                <div
                                    className="Home__bar-chart-toggle-button"
                                    onClick={() => setIsChartOpened(!isChartOpened)}
                                >
                                    {isChartOpened
                                        ? <MdKeyboardArrowUp style={{ width: 20, height: 20, marginTop: 2 }}/>
                                        : <MdKeyboardArrowDown style={{ width: 20, height: 20, marginTop: 2 }}/>
                                    }
                                    {isChartOpened ? 'hide' : 'display'} summary chart
                                </div>
                            </div>
                        </>
                    )}
                    <InView
                        as="div"
                        style={{
                            height: 1,
                            width: '100%',
                            pointerEvents: 'none',
                            transform: 'translateY(-160px)',
                        }}
                        onChange={(inView) => setIsBottomHeaderStick(!inView)}
                    />
                    <HeaderBar className="Home__bar-header">
                        <div className="Home__header-section">
                            {isBottomCounterShown && (
                                <>
                                    {!!totalRecords && (
                                        <div
                                            style={{
                                                fontSize: 30,
                                                paddingRight: 16,
                                            }}
                                            className="font-caption color-dimyellow"
                                        >
                                            <AnimatedNumber value={totalRecords}/>
                                        </div>
                                    )}
                                    <div>Launched menu items & LTO’s</div>
                                </>
                            )}
                            {pathname !== Route.HomeViewChart && !!selectedBarId && (
                                <Button
                                    modifiers={['blue']}
                                    style={{ position: 'relative', width: 100, marginLeft: 20, }}
                                >
                                    {selectedBarId}
                                    <RxCross2
                                        onClick={() => setSelectedBarId(undefined)}
                                        style={{
                                            position: 'absolute',
                                            top: 0,
                                            right: 0,
                                            transform: 'translate(50%, -50%)',
                                            cursor: 'pointer',
                                            color: '#000',
                                            background: '#fff',
                                            borderRadius: '50%',
                                            boxShadow: '0 0 0 3px #fff',
                                            zIndex: 2,
                                            pointerEvents: 'all',
                                        }}
                                    />
                                </Button>
                            )}
                        </div>
                        <div className="Home__header-section">
                            <SelectPageView views={VIEWS}/>
                            {AMONG_PAGES.includes(pathname as Route) && <SelectAmong/>}
                            {METRIC_PAGES.includes(pathname as Route) && <><SelectDisplay/><SelectMetric/></>}
                            {NORM_PAGES.includes(pathname as Route) && <SelectNorm/>}
                        </div>
                    </HeaderBar>
                    <div style={{ minHeight: 450 }}> {/* to fit all the dropdowns in header bar */}
                        <FiltersOverridesContext.Provider value={filterValueOverride}>
                            {pathname === Route.Home && (
                                <TilesView
                                    setTotalRecords={setTotalRecords}
                                    setAppliedFilterPayload={setAppliedFilterPayload}
                                />
                            )}
                            {pathname === Route.HomeViewTable && (
                                <HomeTableView
                                    isTopChartOpened={isChartOpened}
                                    setTotalRecords={setTotalRecords}
                                    setAppliedFilterPayload={setAppliedFilterPayload}
                                />
                            )}
                            {pathname === Route.HomeViewPeople && (
                                <HomePeopleView
                                    isTopChartOpened={isChartOpened}
                                    setTotalRecords={setTotalRecords}
                                    setAppliedFilterPayload={setAppliedFilterPayload}
                                />
                            )}
                        </FiltersOverridesContext.Provider>
                        {pathname === Route.HomeViewChart && (
                            <ChartView
                                setTotalRecords={setTotalRecords}
                                setAppliedFilterPayload={setAppliedFilterPayload}
                            />
                        )}
                    </div>
                </Wrapper>
            </div>
            <FiltersPopup
                hideTilesSort={pathname !== Route.Home}
            />
            <SearchPageActionBar
                filtersPayload={{
                    ...appliedFilterPayload,
                }}
                filtersOverrides={filterValueOverride}
                useAmongRespondentType={AMONG_PAGES.includes(pathname as Route)}
            />
        </SelectedOptionsProvider>
    )
};

const Home: FC = () => {
    const { isLoaded } = useFiltersContext();
    if (!isLoaded) return null;
    return <HomeComponent/>;
};

export default memo(Home);
