import { ID } from '@models';
import useFetch from '@hooks/useFetch';
import { useEffect, useMemo, useState } from 'react';
import { components } from '@api/api';
import { iItem, Item } from '@models/Item';
import { useCategoryActivityFilters } from '@core/selectors/useCategoryActivityFilters';

type Request = components['schemas']['CategoryLaunchesRequest'];
type Response = components['schemas']['CategoryLaunchesRecentlyReleasedItemsResponse'];

export function useCategoryActivityRecentlyReleasedItems(categoryId: ID) {
    const [data, setData] = useState<iItem[]>([]);

    const filter = useCategoryActivityFilters();

    const payload = useMemo((): Request => {
        return {
            filter,
            id: categoryId as number,
        };
    }, [filter, categoryId]);

    const result = useFetch<Response>({
        url: '/api/Category/RecentlyReleasedItems',
        method: 'post',
        payload,
    });

    useEffect(() => {
        if (!result.isLoading) {
            setData((result.data?.items || []).map(i => new Item({ apiModel: i })))
        }
    }, [result.isLoading, result.data]);

    return {
        isLoading: result.isLoading,
        data,
    }
}