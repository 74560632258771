import { FCX, ID } from '@models';
import { useUserContext } from '@core/UserContext';
import { IconCAN, IconUSA } from '@svg/index';
import { useRef, useState, useMemo, useEffect } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import useOutsideClick from '@hooks/useOutsideClick';
import Dropdown from '@components/Select/Dropdown';
import Options from '@components/Select/Options';
import cn from 'classnames';
import { api } from '@hooks/useFetch';
import { getApiLink } from '@core/utils/url';
import { Route } from '@core/routes/Route';
import { Tooltip } from 'react-tooltip';
import Button from '@components/Button';
import { CountriesSelect, ICountriesSelectOption } from "@datassential/platform-ui-lib";

const CountryIcon: FCX<{ countryId: ID }> = ({
    countryId,
    className,
    style,
}) => {
    const props = {
        className,
        style,
    }
    switch (countryId) {
        case 1:
            return <IconUSA {...props}/>;
        case 2:
            return <IconCAN {...props}/>;
        default:
            return null;
    }
}

function getCountryShortNameById(id: ID): string {
    switch (id) {
        case 1:
            return 'USA';
        case 2:
            return 'CAN';
        default:
            return '';
    }
}

function getCountryNameById(id: ID): string {
    switch (id) {
        case 1:
            return 'United States';
        case 2:
            return 'Canada';
        default:
            return '';
    }
}

const Countries: FCX<{
    isPlatform?: boolean;
}> = ({
    isPlatform = false,
}) => {
    const [isOpened, setIsOpened] = useState(false);
    const location = useLocation();

    const ref = useRef(null);

    useOutsideClick({
        ref,
        fn: () => setIsOpened(false),
    });

    const { countries: rawCountries, } = useUserContext();
    const [countries, setCountries] = useState(rawCountries);

    useEffect(() => {
        setCountries(rawCountries);
    }, [rawCountries]);

    const currentCountry = countries.find(c => c.selected);

    const switchCountry = (id: ID) => {
        if (id !== currentCountry?.id) {
            setCountries(countries.map(c => ({
                ...c,
                selected: c.id === id,
            })));
            api.request({
                url: getApiLink('api/User/SetCountry', { countryId: id }),
            }).finally(() => {
                window.location.href = window.location.pathname;
            });
        }
    };

    const isDisabled = useMemo(
        (): boolean => countries.length <= 1
            || !!matchPath(Route.FoodWatchItemCreate, location.pathname)
            || !!matchPath(Route.FoodWatchItemEdit, location.pathname)
            || !!matchPath(Route.ItemDetails, location.pathname)
            || !!matchPath(Route.CLItemDetails, location.pathname)
            || !!matchPath(Route.ChainProfile, location.pathname)
        ,
        [countries, location]
    );

    const countriesOptionsPlatform = useMemo(
        (): ICountriesSelectOption[] => countries.map(c => ({
            id: c.id ?? 0,
            shortName: getCountryShortNameById(c.id ?? 0),
            name: getCountryNameById(c.id ?? 0),
        })),
        [countries]
    )

    if (isPlatform) {
        return (
            <>
                {currentCountry && (
                    <CountriesSelect
                        options={countriesOptionsPlatform}
                        value={currentCountry.id}
                        setValue={(id) => switchCountry(id ?? 0)}
                        isDisabled={isDisabled}
                        className={"MainHeader__countries-select"}
                        testId={"selectDEMOHeaderCountry"}
                    />
                )}
            </>
        );
    }

    return (
        <>
            {currentCountry && (
                <div
                    className={cn(
                        "Header__countries",
                        isOpened && 'is-opened',
                        isDisabled && 'is-disabled',
                    )}
                    ref={ref}
                    onClick={isDisabled ? undefined : () => setIsOpened(!isOpened)}
                    data-tooltip-id="countries-select"
                >
                    <CountryIcon countryId={currentCountry.id ?? 0} style={{ width: 20 }}/>
                    &nbsp;&nbsp;&nbsp;{currentCountry.name}
                    <Tooltip
                        id="countries-select"
                        variant="light"
                        isOpen={isOpened}
                        place="bottom-start"
                        noArrow
                        clickable
                        offset={10}
                        positionStrategy="fixed"
                        style={{ zIndex: 4, padding: 4 }}
                    >
                        {countries.map((country, index) => (
                            <Button
                                key={country.id}
                                modifiers={country.id === currentCountry.id ? ['blue'] : ['blue', 'naked']}
                                onClick={() => switchCountry(country.id ?? 0)}
                                style={{
                                    width: 100,
                                    height: 28,
                                    padding: '0 4px',
                                    justifyContent: 'flex-start',
                                    marginTop: index === 0 ? undefined : 4,
                                }}
                            >
                                <CountryIcon countryId={country.id ?? 0} style={{ width: 16, marginRight: 4 }}/>
                                {country.name?.toUpperCase()}
                            </Button>
                        ))}
                    </Tooltip>
                </div>
            )}
        </>
    );
};

export default Countries;