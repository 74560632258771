import { ID } from '@models';
import useFetch from '@hooks/useFetch';
import { components, paths } from '@api/api';
import { getApiLink } from '@core/utils/url';
import { useEffect, useState } from 'react';
import { Item } from '@models/Item';

export function useChartItem (itemId: ID | undefined, respondentTypeId: ID | null) {
    const [data, setData] = useState<Item | null>(null);

    useEffect(() => {
        setData(null);
    }, [itemId]);

    const {
        isLoading,
        data: responseData,
        doFetch,
    } = useFetch<components['schemas']['ItemViewModel']>({
        url: getApiLink('/api/Items/GetChartItem', { itemId, respondentTypeId }) as keyof paths,
        lazy: true,
    });

    useEffect(() => {
        if (itemId !== undefined && respondentTypeId !== null) {
            doFetch();
        }
    }, [itemId, respondentTypeId, doFetch])

    useEffect(() => {
        if (responseData) {
            setData(new Item({ apiModel: responseData }))
        }
    }, [responseData]);

    return {
        isLoading: isLoading,
        data,
    }
}