import { FCX, ID, Season } from '@models';
import React, { ReactNode, useMemo, useState } from 'react';
import { iSeasonalFood, useSeasonalByFoods } from '@api/useSeasonalByFoods';
import SimpleTable from '@components/Table/SimpleTable';
import DataFallback from '@components/DataFallback';
import { getFormattedThousand, getWithFixedDecimals } from '@core/utils/number';
import { getFilterSort, useFilterSort } from '@hooks/useFilterSort';
import Button from '@components/Button';
import BarChart from '@components/BarChart';
import { iBarChartItem } from '@components/BarChart/BarChart';
import Loader from '@components/Loader';
import FoodFlavorsTableConfig from './FoodFlavorsTableConfig';
import Modal from '@components/Modal';
import FoodFlavorsTableSortingConfig from '@pages/SeasonalActivity/FoodFlavorsTableSortingConfig';
import VirtualizedTable from '@components/Table/VirtualizedTable';
import SelectKeywordCategories from '@vms/SelectKeywordCategories';
import { intersect } from '@core/utils/array';
import ButtonClipboardCopy from "@components/ButtonClipboardCopy";
import copy from "copy-to-clipboard";
import { useSeasonalKeywordsReport } from "@api/useSeasonalKeywordsReport";
import BadgeDownloadPptButton from "@components/ButtonClipboardCopy/BadgeDownloadPptButton";

const NOTE_TEXT_BEFORE = '* Indicates food or flavors that only appear in';
const NOTE_TEXT_AFTER = 'during the filtered period';

function getChartLegend(field: keyof iSeasonalFood): ReactNode {
    let message = '';

    switch (field) {
        case 'index':
            message = 'Index vs. other seasons';
            break;
        case 'count':
            message = 'Number of Menu Launches';
            break;
        case 'percentage':
            message = 'Percent of Menu Launches';
            break;
    }

    return (
        <div
            style={{
                textAlign: 'right',
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                padding: '10px 20px',
            }}
            className="font-medium"
        >
            {message}
        </div>
    )
}

function getFilteredData(
    data: iSeasonalFood[],
    selectedCategories: ID[],
): iSeasonalFood[] {
    if (selectedCategories.length === 0) {
        return data;
    }
    return data?.filter(i => intersect(selectedCategories as number[], i.groupKeyWordIds || []));
}

const FoodsFlavors: FCX<{
    selectedSeason: Season;
    renderSeasonName: ReactNode;
    isGlobalLoading: boolean;
}> = ({
    selectedSeason,
    renderSeasonName,
}) => {
    const { isLoading, data: rawData } = useSeasonalByFoods();
    const [isPopupOpened, setIsPopupOpened] = useState(false);
    const [selectedCategories, setSelectedCategories] = useState<ID[]>([]);
    const report = useSeasonalKeywordsReport(selectedCategories);

    const selectedSeasonData = useMemo(
        () => rawData[selectedSeason],
        [rawData, selectedSeason]
    );

    const filteredData = useMemo(
        () => getFilteredData(selectedSeasonData, selectedCategories),
        [selectedSeasonData, selectedCategories]
    );

    const {
        data: sortedData,
        sortingDirection,
        sortingColumn,
        setSortingColumn,
        setSortingDirection,
    } = useFilterSort(filteredData, 'index');

    const copyTableData = useMemo(
        (): any[][] => {
            const rows: any[][] = [];

            const seasonsData = Object.keys(rawData).map(season => {
                const seasonRawData = rawData[season as Season];
                const seasonFilteredData = getFilteredData(seasonRawData, selectedCategories);
                const seasonData = getFilterSort({
                    data: seasonFilteredData,
                    sortingColumn,
                    sortingDirection,
                });

                return [
                    [season, '', '', ''],
                    ['Term', 'Index', 'Count', 'Percentage'],
                    ...seasonData.slice(0, 500).map(i => [
                        i.name,
                        i.index === Infinity ? '*' : i.index,
                        i.count,
                        `${getWithFixedDecimals(i.percentage ?? 0, 2)}%`,
                    ]),
                ];
            });

            const rowsCount = Math.max(...seasonsData.map(i => i.length));

            for (let i = 0; i < rowsCount; i++) {
                const row = seasonsData.map(
                    (seasonData, index) => index === 0
                        ? (seasonData[i] || ['', '', '', ''])
                        : (['', ...seasonData[i] || ['', '', '', '']])
                );
                rows.push(row.flat());
            }

            rows[0].push(`${NOTE_TEXT_BEFORE} specified season ${NOTE_TEXT_AFTER}`)

            return rows;
        },
        [rawData, selectedCategories, sortingDirection, sortingColumn]
    );

    const copyTableText = useMemo(
        () => copyTableData.map(row => row.join('\t')).join('\n'),
        [copyTableData]
    );

    const data = useMemo(
        () => sortedData.slice(0, 10),
        [sortedData],
    );

    const chartSizer = useMemo(
        (): number | undefined => {
            if (sortingColumn === 'percentage') return 100;

            let result: number;

            const isInfiniteIndexPresent = filteredData.some(i => i[sortingColumn] === Infinity);
            const maxFiniteValue = Math.max(
                ...filteredData.map(
                    i => i[sortingColumn] === Infinity ? 0 : Number(i[sortingColumn] || 0)
                )
            );

            if (isInfiniteIndexPresent) {
                result = maxFiniteValue * 1.1;
            } else {
                result = maxFiniteValue;
            }

            if (sortingColumn === 'index') {
                return Math.max(result, 200);
            }

            return result;
        },
        [filteredData, sortingColumn]
    );

    const chartData = useMemo(
        (): iBarChartItem[] => data.map(i => ({
            id: i.id || 0,
            title: i.name || '',
            value: (i[sortingColumn] === Infinity && chartSizer) ? chartSizer : Number(i[sortingColumn] || 0),
            valueLabelOverride: i[sortingColumn] === Infinity ? '*' : undefined,
        })),
        [data, sortingColumn, chartSizer]
    );

    return (
        <div className="card" style={{ flex: '0 1 100%' }}>
            <div
                className="card-header"
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    zIndex: 3,
                    position: 'relative',
                }}
            >
                <div>Foods / Flavors in&nbsp;{renderSeasonName}&nbsp;Launches</div>
                <div className="font-normal">
                    <SelectKeywordCategories
                        label="Food & Flavor Filters"
                        value={selectedCategories}
                        setValue={setSelectedCategories}
                        style={{ marginBottom: 5, minWidth: 170 }}
                    />
                </div>
            </div>
            <div className="card-content" style={{ paddingBottom: 0, position: "relative" }}>
                {!isLoading && data.length > 0 && (
                    <>
                        <ButtonClipboardCopy
                            style={{ top: 20 }}
                            onClick={() => copy(copyTableText, { format: 'text/plain' })}
                        />
                        <BadgeDownloadPptButton
                            style={{ top: 90 }}
                            isBusy={report.isLoading}
                            onClick={() => report.load()}
                        />
                    </>
                )}
                {isLoading || data.length > 0 ? (
                    <>
                        <div style={{ display: 'flex' }}>
                            <div
                                className="card"
                                style={{ minHeight: 375, flex: '0 1 100%', marginBottom: 0 }}
                            >
                                {isLoading ? (
                                    <div style={{ padding: 20 }}>
                                        <div style={{ position: 'relative', height: 300 }}>
                                            <Loader/>
                                        </div>
                                    </div>
                                ) : (
                                    <div
                                        className="card-content"
                                        style={{
                                            height: '100%',
                                            position: 'relative',
                                            padding: '38px 20px',
                                        }}
                                    >
                                        <BarChart
                                            data={chartData}
                                            legend={getChartLegend(sortingColumn)}
                                            itemHeight={30}
                                            valueFormatter={(value) => sortingColumn === 'percentage'
                                                ? `${Math.round(value)}%`
                                                : getFormattedThousand(value)
                                            }
                                            sizer={chartSizer}
                                            norm={sortingColumn === 'index' ? {
                                                value: 100,
                                                label: <>
                                                    Average <br/>
                                                    [100]
                                                </>,
                                            } : undefined}
                                        />
                                    </div>
                                )}
                            </div>
                            <FoodFlavorsTableSortingConfig
                                renderSeasonName={renderSeasonName}
                                sortingColumn={sortingColumn}
                                setSortingColumn={setSortingColumn}
                                setSortingDirection={setSortingDirection}
                                sortingDirection={sortingDirection}
                            >
                                <FoodFlavorsTableConfig
                                    id="top10"
                                    data={data}
                                    renderSeasonName={renderSeasonName}
                                    sortingColumn={sortingColumn}
                                >
                                    <SimpleTable
                                        style={{ minHeight: 375, flex: '0 1 100%', marginLeft: 20 }}
                                        rowsCount={isLoading ? 10 : data.length}
                                        isLoading={isLoading}
                                    >
                                        {!isLoading && data.length < sortedData.length && (
                                            <div style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                paddingTop: 5,
                                            }}>
                                                <Button
                                                    style={{ height: 24 }}
                                                    modifiers={['naked', 'blue']}
                                                    onClick={() => setIsPopupOpened(true)}
                                                >
                                                    show more
                                                </Button>
                                            </div>
                                        )}
                                    </SimpleTable>
                                </FoodFlavorsTableConfig>
                            </FoodFlavorsTableSortingConfig>
                        </div>
                        <div
                            className="font-medium"
                            style={{ display: 'flex', height: 50, alignItems: 'center', justifyContent: 'flex-end' }}
                        >
                            {NOTE_TEXT_BEFORE}&nbsp;
                            <div className="font-bold">
                                {selectedSeason}
                            </div>
                            &nbsp;{NOTE_TEXT_AFTER}
                        </div>
                    </>
                ) : (
                    <div className="card-content" style={{ height: 350 }}>
                        <DataFallback
                            style={{ opacity: isLoading ? 0 : 1, height: '100%' }}
                            title="No results"
                        />
                    </div>
                )}
            </div>
            {isPopupOpened && (
                <Modal
                    onClose={() => setIsPopupOpened(false)}
                    style={{
                        padding: 0,
                    }}
                >
                    <ButtonClipboardCopy
                        style={{ top: 20 }}
                        onClick={() => copy(copyTableText, { format: 'text/plain' })}
                    />
                    <FoodFlavorsTableSortingConfig
                        renderSeasonName={renderSeasonName}
                        sortingColumn={sortingColumn}
                        setSortingColumn={setSortingColumn}
                        setSortingDirection={setSortingDirection}
                        sortingDirection={sortingDirection}
                    >
                        <FoodFlavorsTableConfig
                            id="all"
                            data={sortedData}
                            renderSeasonName={renderSeasonName}
                            sortingColumn={sortingColumn}
                        >
                            <div style={{ height: 500, width: 800 }}>
                                <VirtualizedTable
                                    rowsCount={sortedData.length}
                                    isLoading={isLoading}
                                    style={{ paddingTop: 0, paddingBottom: 0 }}
                                    headStyle={{
                                        paddingTop: 8,
                                        height: 38,
                                    }}
                                />
                            </div>
                        </FoodFlavorsTableConfig>
                    </FoodFlavorsTableSortingConfig>
                </Modal>
            )}
        </div>
    );
};

export default FoodsFlavors;