import React, { FC, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Header from '@vms/Header';
import { FiltersPopupContextProvider } from '@vms/FiltersPopup/FiltersPopupContext';
import ScrollTopButton from '@vms/Layout/ScrollTopButton';
import { PlatformNavigation, usePlatformFeatureFlag } from "@datassential/platform-ui-lib";

const Layout: FC = () => {
    const location = useLocation();

    useEffect(() => {
        document.scrollingElement?.scrollTo({ top: 0 })
    }, [location])

    const isPlatformEnabled = usePlatformFeatureFlag('enable platform');

    return (
        <div className="Layout">
            <FiltersPopupContextProvider>
                {isPlatformEnabled && <PlatformNavigation/>}
                <Header/>
                <Outlet/>
                <ScrollTopButton/>
            </FiltersPopupContextProvider>
        </div>
    );
}

export default Layout;