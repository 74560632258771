import { FC, useCallback, useRef, useState } from 'react';
import { IconChart, IconClLogo, IconHome, IconLogo } from '@svg/index';
import Wrapper from '@components/Wrapper';
import { Link, NavLink } from 'react-router-dom';
import { Route } from '@core/routes/Route';
import { useUserContext } from '@core/UserContext';
import { MdKeyboardArrowDown } from 'react-icons/md';
import Countries from '@vms/Header/Countries';
import Search from '@vms/Header/Search';
import { useFiltersContext } from '@core/FiltersContext';
import { FiltersVariant } from '@models/Filter';
import cn from 'classnames';
import { BiLinkExternal } from 'react-icons/bi';
import { BsFillGearFill } from 'react-icons/bs';
import useOutsideClick from '@hooks/useOutsideClick';
import { VscBell } from 'react-icons/vsc';
import { useIsPlatform } from "@hooks/useIsPlatform";
import PlatformHeader from "@vms/Header/PlatformHeader";


const Header: FC = () => {
    const [isUserMenuOpened, setIsUserMenuOpened] = useState(false);
    const userMenuRef = useRef<HTMLDivElement>(null);
    const [isDropdownItemsHidden, setIsDropdownItemsHidden] = useState(false);
    const isPlatformEnabled = useIsPlatform();

    useOutsideClick({
        ref: userMenuRef,
        fn: () => setIsUserMenuOpened(false),
    });
    const { initials, hasAdminAccess, hasConceptLockerAccess } = useUserContext();
    const { resetSpecificValue } = useFiltersContext();

    const onDropdownClick = useCallback(
        () => {
            setIsDropdownItemsHidden(true);
            setTimeout(() => {
                setIsDropdownItemsHidden(false)
            }, 100);
        },
        [],
    );

    if (isPlatformEnabled) {
        return (
            <PlatformHeader/>
        );
    }

    return (
        <div className="Header">
            <Wrapper>
                <div className="Header__inner">
                    <div>
                        <Link
                            style={{ fontSize: 0, }}
                            to={Route.Home}
                            onClick={() => resetSpecificValue(FiltersVariant.Homepage)}
                        >
                            <IconLogo style={{ height: 50, width: 280 }}/>
                        </Link>

                        <Countries/>
                    </div>
                    <div>
                        <Search/>
                    </div>
                    <div>
                        <div className="Header__menu">
                            <NavLink to={Route.Home} className="Header__menu-item">
                                <div className="Header__menu-item-icon">
                                    <IconHome/>
                                </div>
                                <div className="Header__menu-item-title">
                                    Home
                                </div>
                            </NavLink>
                            <div className="Header__menu-item">
                                <div className="Header__menu-item-icon">
                                    <IconChart/>
                                </div>
                                <div className="Header__menu-item-title">
                                    Analyze <MdKeyboardArrowDown style={{ width: 20, height: 20, }}/>
                                </div>
                                {!isDropdownItemsHidden && (
                                    <div
                                        className="Header__menu-item-dropdown Header__dropdown"
                                        onClick={onDropdownClick}
                                    >
                                        <NavLink to={Route.FoodsFlavors} className="Header__dropdown-item">
                                            Specific foods & flavors
                                        </NavLink>
                                        <NavLink to={Route.ChainPerformance} className="Header__dropdown-item">
                                            Performance by chain
                                        </NavLink>
                                        <NavLink to={Route.Benchmark} className="Header__dropdown-item">
                                            Benchmark non-SCORES concepts
                                        </NavLink>
                                        <NavLink to={Route.SeasonalActivity} className="Header__dropdown-item">
                                            Seasonal Activity
                                        </NavLink>
                                        <NavLink to={Route.CategoryActivity} className="Header__dropdown-item">
                                            Category Activity
                                        </NavLink>
                                    </div>
                                )}
                            </div>
                            <NavLink
                                to={Route.ConceptLocker}
                                className={cn("Header__menu-item", !hasConceptLockerAccess && 'is-disabled' )}>
                                <div className="Header__menu-item-icon" style={{ height: 30, marginBottom: 6 }}>
                                    <IconClLogo/>
                                </div>
                                <div className="Header__menu-item-title">
                                    Concept Locker
                                </div>
                            </NavLink>
                            <NavLink to={Route.FoodWatch} className="Header__menu-item">
                                <div className="Header__menu-item-icon" style={{ height: 30, marginBottom: 6 }}>
                                    <VscBell/>
                                </div>
                                <div className="Header__menu-item-title">
                                    Food Watch
                                </div>
                            </NavLink>
                        </div>
                        <div
                            ref={userMenuRef}
                            className={cn("Header__user-menu", isUserMenuOpened && 'is-opened')}
                        >
                            <div
                                className="Header__user-menu-item"
                                onClick={() => setIsUserMenuOpened(!isUserMenuOpened)}
                            >
                                <div className="Header__user-menu-item-icon">
                                    {initials}
                                </div>
                            </div>
                            {hasAdminAccess && (
                                <a
                                    className="Header__user-menu-item is-expand"
                                    href={process.env.REACT_APP_ADMIN}
                                    rel="noreferrer"
                                    target="_blank"
                                    onClick={() => setIsUserMenuOpened(false)}
                                >
                                    <div className="Header__user-menu-item-title">
                                        ADMIN
                                    </div>
                                    <div className="Header__user-menu-item-icon">
                                        <BsFillGearFill style={{
                                            width: 20,
                                            height: 20,
                                        }}/>
                                    </div>
                                </a>
                            )}
                            <a
                                className="Header__user-menu-item is-expand"
                                href={process.env.REACT_APP_SNAP}
                                onClick={() => setIsUserMenuOpened(false)}
                            >
                                <div className="Header__user-menu-item-title">
                                    BACK TO SNAP
                                </div>
                                <div className="Header__user-menu-item-icon">
                                    <BiLinkExternal style={{
                                        width: 24,
                                        height: 24,
                                    }}/>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </Wrapper>
        </div>
    );
};

export default Header;