export function getFormattedThousand(number: number = 0, separator: string = ','): string {
    if (number < 0) return `-${getFormattedThousand(-number)}`;
    let numberString = Math.trunc(number).toString();
    let result = "";
    while (numberString.length > 3) {
        result = `${separator}${numberString.substring(numberString.length - 3)}${result}`;
        numberString = numberString.substring(0, numberString.length - 3);
    }

    result = `${numberString}${result}`;
    return result;
}

export function getDecimal(n: number): number {
    const int = parseInt(`${n}`);
    return n - int;
}

export function getWithFixedDecimals (number: number, digitsNumber = 1): number {
    const multiplier = Math.pow(10, digitsNumber);
    return Math.round(number * multiplier) / multiplier;
}

export function getDecimalAfterPoint (n: number, decimalSize: number = 2): string {
    return getDecimal(n).toFixed(decimalSize).replace(/^-?0./, '');
}

export function getNumberWithDecimal(n: number, decimalSize: number = 1): string {
    const points = getDecimalAfterPoint(n, decimalSize);
    return Number.parseInt(points) ? `${n}` : `${n}.${points}`;
}

export function getFormattedThousandWithDecimal(n: number, decimalSize: number = 2): string {
    return `${getFormattedThousand(n)}.${getDecimalAfterPoint(n, decimalSize)}`;
}

export function getFormattedCurrency(number: number, separator: string = ',', currency = "$") {
    return `${currency}${getFormattedThousand(parseInt(`${number}`), separator)}.${getDecimalAfterPoint(number)}`;
}

export function getFormattedPrice(price: number): string {
    return price.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });
}

export function getWithSign(
    num: number,
    valueFormatter: (n: number) => string = (n) => `${n}`
): string {
    if (num > 0) return `+${valueFormatter(num)}`;
    return valueFormatter(num);
}

export function getAverage (values: number[]): number {
    if (values.length === 0) return 0;
    return values.reduce((acc, v) => acc + v, 0) / values.length;
}