import { createContext, useContext } from 'react';
import { components, paths } from '@api/api';
import { FCX } from '@models';
import useFetch from '@hooks/useFetch';
import { getApiLink } from '@core/utils/url';

type ApiModel = components['schemas']['UserViewModel'];
const path: keyof paths = '/api/User';

interface IUserData extends ApiModel {
    isDs: boolean;
}

const DEFAULT_USER_DATA: IUserData = {
    isDs: false,
    id: '',
    companyId: 0,
    companyName: '',
    countries: [],
    firstName: '',
    lastName: '',
    initials: '',
    freeAccess: false,
    fullAccess: true,
    hasConceptLockerAccess: true,
    hasAdminAccess: false,
};

const UserContext = createContext<IUserData>(DEFAULT_USER_DATA);

export function useUserContext() {
    return useContext(UserContext);
}

export const UserContextProvider: FCX = ({
    children,
}) => {
    const { isLoading, data } = useFetch<ApiModel>({
        url: getApiLink(path) as keyof paths,
    });

    const isLoaded = !isLoading && data !== null;

    return (
        <UserContext.Provider
            value={
                isLoaded
                    ? {
                        ...data,
                        isDs: data?.companyId === 1,
                    }
                    : DEFAULT_USER_DATA
            }
        >
            {children}
        </UserContext.Provider>
    );
};