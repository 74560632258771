import useFetch from "@hooks/useFetch";
import { components, paths } from "@api/api";
import { useEffect, useMemo, useState } from "react";
import { useSkipTake } from '@hooks/useSkipTake';
import { iItem, Item } from '@models/Item';
import { useFiltersOverridesContext } from '@core/FiltersContext';
import dayjs from 'dayjs';
import { useFromToOverrides } from '@hooks/useFromToOverrides';

const TILES_TABLE_URL: keyof paths = '/api/Search/GetItems';
const TILES_COPY_TABLE_URL: keyof paths = '/api/Search/CopyTableString';


export function useItems (
    filtersPayload: components['schemas']['SearchFilterModel'],
    perPage: number,
    loadCopyTable: boolean = false,
)  {
    const filtersOverrides = useFiltersOverridesContext();
    const [totalRecords, setTotalRecords] = useState<number | undefined>(undefined);
    const {
        currentPage,
        reset,
        inc,
    } = useSkipTake(perPage, totalRecords);

    const [data, setData] = useState<iItem[]>([]);

    useEffect(() => {
        setData([]);
        reset();
    }, [filtersPayload, reset, filtersOverrides]);

    useEffect(() => {
        if (currentPage === 1) {
            setData([]);
        }
    }, [currentPage]);

    const fromToOverrides = useFromToOverrides(
        filtersPayload,
        filtersOverrides
    );

    const tilesCopyTableDataPayload: components['schemas']['SearchFilterModel'] = useMemo(() => {
        return {
            ...filtersPayload,
            filter: {
                ...filtersPayload.filter,
                date: {
                    ...filtersPayload.filter?.date,
                    ...(fromToOverrides || {}),
                }
            },
            page: {
                ...filtersPayload.page,
                currentPage: 1,
                perPage: 1,
            },
        };
    }, [filtersPayload, fromToOverrides]);

    const tilesTableDataPayload: components['schemas']['SearchFilterModel'] = useMemo(() => {

        return {
            ...filtersPayload,
            filter: {
                ...filtersPayload.filter,
                date: {
                    ...filtersPayload.filter?.date,
                    ...(fromToOverrides || {}),
                }
            },
            page: {
                ...filtersPayload.page,
                currentPage,
                perPage,
            },
        };
    }, [filtersPayload, currentPage, perPage, fromToOverrides]);

    const tilesTableData = useFetch<components['schemas']['SearchResultModel']>({
        url: TILES_TABLE_URL,
        method: 'post',
        payload: tilesTableDataPayload,
    });

    const tilesCopyTableData = useFetch<string>({
        url: TILES_COPY_TABLE_URL,
        method: 'post',
        payload: tilesCopyTableDataPayload,
        lazy: !loadCopyTable,
    });

    useEffect(() => {
        if (tilesTableData.data) {
            setData((prevData) => [
                ...prevData,
                ...tilesTableData.data?.items?.map(i => new Item({ apiModel: i })) || [],
            ]);
            setTotalRecords(tilesTableData.data?.count);
        }
    }, [tilesTableData.data]);

    return {
        data,
        totalRecords,
        isLoading: tilesTableData.isLoading,
        fetchMore: inc,
        isCopyTableDataLoading: tilesCopyTableData.isLoading,
        copyTableData: tilesCopyTableData.data,
    };
}